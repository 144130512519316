import React from 'react'
import Content from '../templates/content'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui';
import 'react-image-gallery/styles/css/image-gallery.css'
import ImageGallery from 'react-image-gallery'
import { graphql } from 'gatsby'

const useStyles = makeStyles()(theme => ({
  root: {},
}));

const PressPage = ({ data }) => {
  const { classes } = useStyles()
  const images = data.allFile.edges.map(edge => {
    const node = edge.node
    return {
      original: node.childImageSharp.original.src,
      thumbnail: node.childImageSharp.fixed.src,
    }
  })

  return (
    <Content title="Pressestimmen">
      <Box className={classes.root}>
        <ImageGallery items={images} />
      </Box>
    </Content>
  )
}

export const query = graphql`
  query {
    allFile(filter: { dir: { regex: "//images/press/" } }) {
      edges {
        node {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            original {
              src
            }
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`

export default PressPage
